import styled from 'styled-components'

export const Nodata = styled.div`
  position: relative;  
  text-align:center;
`

export const NodataContainer= styled.div`
  position: relative;    
  width:100%;
  margin:0 auto;
  max-width:${props=>props.maxWidth};
`
NodataContainer.defaultProps = {
	maxWidth: "715px",
}

export const NodataMedia = styled.div`
  position: relative;
  margin-bottom:30px;
  .gatsby-image-wrapper{
    display:inline-block;
  }
`

export const NodataTitle = styled.h1`
  margin-bottom:30px;
`
export const NodataSubTitle = styled.h2`
  margin-bottom:0;
`
export const NodataDesc = styled.div`
  margin-bottom:${props=>props.mb};
  p{
    color: ${props=>props.color};
    @media(min-width:576px){
      margin-bottom:30px;
    }
  }
`
NodataDesc.defaultProps = {
  color: "#333",
}